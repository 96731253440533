$font-size-micro: 9px;
$font-size-mini: 11px;
$font-size-tiny: 12px;
$font-size-small: 14px;
$font-size: 16px;
$font-size-large: 18px;
$font-size-big: 20px;
$font-size-xlarge: 32px;

$font-size-h3Mobile: 22px;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,700;1,400;1,700&display=swap');

// $font-site: -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-site: 'Archivo', sans-serif;
$font-code: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

$white: #fff;

$gray-900: #16171A;
$gray-700: #5A617D;
$gray-500: #838CA8;
$gray-400: #C1C7DA;
$gray-300: #E6E9F2;
$gray-200: #e5e7eb;
$gray-100: #F5F6FA;
$gray-50: #f9fafb;

$gray-scale: (
  900: $gray-900,
  700: $gray-700,
  500: $gray-500,
  400: $gray-400,
  300: $gray-300,
  200: $gray-200,
  100: $gray-100,
  50: $gray-50,
);

$green-900: #064e3b;
$green-700: #047857;
$green-500: #10b981;
$green-400: #34d399;
$green-300: #6ee7b7;
$green-200: #a7f3d0;
$green-100: #d1fae5;
$green-50: #ecfdf5;

$green-scale: (
  900: $green-900,
  700: $green-700,
  500: $green-500,
  400: $green-400,
  300: $green-300,
  200: $green-200,
  100: $green-100,
  50: $green-50,
);

$yellow-900: #78350f;
$yellow-700: #b45309;
$yellow-500: #f59e0b;
$yellow-400: #fbbf24;
$yellow-300: #fcd34d;
$yellow-200: #fde68a;
$yellow-100: #fef3c7;
$yellow-50: #fffbeb;

$yellow-scale: (
  900: $yellow-900,
  700: $yellow-700,
  500: $yellow-500,
  400: $yellow-400,
  300: $yellow-300,
  200: $yellow-200,
  100: $yellow-100,
  50: $yellow-50,
);

$red-900: #7f1d1d;
$red-700: #b91c1c;
$red-500: #ef4444;
$red-400: #f87171;
$red-300: #fca5a5;
$red-200: #fecaca;
$red-100: #fee2e2;
$red-50: #fef2f2;

$red-scale: (
  900: $red-900,
  700: $red-700,
  500: $red-500,
  400: $red-400,
  300: $red-300,
  200: $red-200,
  100: $red-100,
  50: $red-50,
);

$blue-900: #1e3a8a;
$blue-700: #1d4ed8;
$blue-500: #3b82f6;
$blue-400: #60a5fa;
$blue-300: #93c5fd;
$blue-200: #bfdbfe;
$blue-100: #dbeafe;
$blue-50: #eff6ff;

$blue-scale: (
  900: $blue-900,
  700: $blue-700,
  500: $blue-500,
  400: $blue-400,
  300: $blue-300,
  200: $blue-200,
  100: $blue-100,
  50: $blue-50,
);

$main-color: #004E48;
$admin-color: #004E48;
$link-color: #004E48;

$orange: #f59e0b;

$basic-shadow: 0px 1px 0px rgba(0, 35, 140, 0.06), 0px 2px 6px -1px rgba(0, 7, 26, 0.1);

// Queries
$medias: "sm", "md", "lg", "xl";
$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;

@mixin media($media) {
  @if $media == "sm" {
    @media only screen and (max-width: #{$md-width - 0.1px}) {
      @content;
    }
  } @else if $media == "xs" {
    @media only screen and (max-width: #{$sm-width - 0.1px}) {
      @content;
    }
  } @else if $media == ">=sm" {
    @media only screen and (min-width: #{$sm-width}) {
      @content;
    }
  } @else if $media == "<=md" {
    @media only screen and (max-width: #{$lg-width - 0.1px}) {
      @content;
    }
  } @else if $media == "md" {
    @media only screen and (min-width: #{$md-width}) and (max-width: #{$lg-width - 0.1px}) {
      @content;
    }
  } @else if $media == ">=md" {
    @media only screen and (min-width: #{$md-width}) {
      @content;
    }
  } @else if $media == "<lg" {
    @media only screen and (max-width: #{$xl-width - 0.1px}) {
      @content;
    }
  } @else if $media == "lg" {
    @media only screen and (min-width: #{$lg-width}) and (max-width: #{$xl-width - 0.1px}) {
      @content;
    }
  } @else if $media == ">=lg" {
    @media only screen and (min-width: #{$lg-width}) {
      @content;
    }
  } @else if $media == "xl" {
    @media only screen and (min-width: #{$xl-width}) {
      @content;
    }
  }
}

@function textColorFor($color) {
  @if (lightness($color) > 60) {
    @return $gray-900; // Lighter background, return dark color
  } @else {
    @return $gray-100; // Darker background, return light color
  }
}

@mixin disabled() {
  cursor: not-allowed;
  opacity: 0.45 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
}
