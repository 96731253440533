@import "~@fider/assets/styles/variables.scss";

#p-signin {
  margin-top: 20px;
  text-align: center;

  @include media(">=md") {
    margin-top: 60px;
    max-width: 500px !important;
  }

  .message {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 20px;
    img {
      max-height: 100px;
      margin-bottom: 10px;
    }
    .welcome {
      font-size: $font-size-big;
    }
  }
}
